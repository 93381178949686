<template>
  <v-main id="home-page">
    <!-- Hero Banner -->
    <v-sheet class="hero-banner" id="hero-banner" color="grey darken-3" dark>
      <v-sheet class="d-flex" style="min-height: 480px" color="rgba(0, 24, 56, 0.33)" dark>
        <v-container class="nv-container hero-banner-container">
          <v-row justify="center">
            <v-col>
              <v-img class="mx-auto mb-4" :src="require('../assets/img/novarous_icon_shadow_512x.png')" :aspect-ratio="1" max-width="128px" contain></v-img>
              <h2 class="text-h2 mb-4">
                Novarous
              </h2>
              <p class="text-subtitle-1">
                Create professional-quality music videos and animations with little to no experience.
              </p>
              <v-btn @click="$vuetify.goTo('#features')">
                Get Started
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-sheet>

    <!-- Introduction -->
    <v-sheet id="introduction" color="grey darken-4" dark>
      <v-divider></v-divider>
      <v-container class="nv-container py-8">
        <v-row justify="center">
          <v-col cols="12">
            <v-card class="rounded-lg overflow-hidden mb-4" style="border: 1px solid #616161" elevation="6" data-aos="fade-up">
              <v-img :aspect-ratio="16/9" width="100%">
                <iframe src="https://www.youtube-nocookie.com/embed/hCFAHwLG1Nk?rel=0" title="Made with Novarous #1" frameborder="0" style="width: 100%; height: 100%" allow="accelerometer; encrypted-media; gyroscope;" allowfullscreen=""></iframe>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <h4 class="text-h4 mb-4">
              Streams Made Easy
            </h4>
            <p class="text-subtitle-1 mb-0">
              Whether you are an artist, content creator, producer, or anyone who needs a fast and dynamic way of creating visuals, Novarous provides a dynamic solution.<br>
              You can use our built-in templates or create your own custom visualizations.<br>
              This is not your average visualization software.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    
    <!-- Editor -->
    <v-sheet class="hero-banner" id="editor" color="black" dark>
      <v-divider></v-divider>
      <v-container class="nv-container py-8">
        <v-row justify="center">
          <v-col>
            <v-card class="rounded-lg overflow-hidden mb-4" style="border: 1px solid #616161" elevation="6" data-aos="fade-up">
              <v-img :src="require('../assets/img/home/editor.jpg')"></v-img>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <h4 class="text-h4 mb-4">
              Robust Editor
            </h4>
            <p class="text-subtitle-1 mb-0">
              Our editor allows you to animate any type of visualization your can conceive.<br>
              It is community-driven and built by musicians for musicians.<br> 
              This tool allows you to create, use and save templates. You can also use community-created templates.<br>
              Give Novarous a try and see how easy it is to get your work published.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <!-- Get Started -->
    <v-sheet id="get-started" color="grey darken-4" dark>
      <v-divider></v-divider>
      <v-container class="nv-container py-8">
        <v-row justify="center">
          <v-col cols="12">
            <h4 class="text-h4 mb-4">
              Get Novarous
            </h4>
            <p class="text-subtitle-1">
              
            </p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-card class="rounded-lg overflow-hidden mb-4" style="border: 1px solid #FF5722" elevation="6">
              <v-card-title class="justify-center">
                Basic
              </v-card-title>
              <v-card-subtitle class="text-subtitle-1">
                Free
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-list one-line>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Novarous
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Built-in Presets
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Reactive Components
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Export Presets
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Watermarked Videos
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-card-actions class="justify-center py-4">
                <v-btn color="deep-orange">
                  Get Started
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="rounded-lg overflow-hidden mb-4" style="border: 1px solid #2196F3" elevation="6">
              <v-card-title class="justify-center">
                Plus
              </v-card-title>
              <v-card-subtitle class="text-subtitle-1">
                $9.99 / Month
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-list class="mb-0" one-line>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Novarous
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Built-in Presets
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Reactive Components
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Export Presets
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No Watermark
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-card-actions class="justify-center py-4">
                <v-btn color="blue">
                  Get Started
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="12" md="4">
            <v-card class="rounded-lg overflow-hidden mb-4" style="border: 1px solid #616161" elevation="6" data-aos="fade-up">
              <v-card-title class="justify-center">
                Minimum Requirements
              </v-card-title>
              <v-divider></v-divider>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Operating System
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      Windows 7 SP1 x64
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Processor
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      Intel Core i5-4460 / AMD FX-4350
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Memory
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      4GB RAM
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Graphics
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      Nvidia GeForce MX150 / AMD Vega 8 / Intel Iris Pro
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Storage
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      4GB Free Space
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="rounded-lg overflow-hidden mb-4" style="border: 1px solid #616161" elevation="6" data-aos="fade-up">
              <v-card-title class="justify-center">
                Recommended Requirements
              </v-card-title>
              <v-divider></v-divider>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Operating System
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      Windows 10 20H2 x64
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Processor
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      Intel Core i5-8500 / AMD Ryzen 5 2600
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Memory
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      8GB RAM
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Graphics
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      Nvidia GeForce 1050 Ti / AMD Radeon RX 560 / Intel Iris Xe
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Storage
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      8GB Free Space
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-main>
</template>

<style>
.hero-banner {
  background: rgb(255,77,10);
  background: linear-gradient(135deg, rgba(255,77,10,0.9) 0%, rgba(21,63,212,0.9) 100%);
}

.hero-banner-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.background-gradient-1 {
  background: rgb(54,54,54);
  background: linear-gradient(135deg, rgba(54,54,54,1) 0%, rgba(42,42,42,1) 100%);
}
</style>

<script>
  export default {
    name: 'Home',

    data: () => ({
      featureList: [
        { name: "Images", icon: "mdi-image", desc: "Import JPEG and PNG image files." },
        { name: "Video", icon: "mdi-video", desc: "Import MP4 and WEBM video files." },
        { name: "Audio", icon: "mdi-waveform", desc: "Import MP3, WAV, and OGG audio files." },
        { name: "Text", icon: "mdi-format-text", desc: "Text overlays for artist, song name, and more." },
        { name: "Visualizers", icon: "mdi-movie-star", desc: "Reactive audio visualizers and effects." },
        { name: "Export", icon: "mdi-export", desc: "Exports to all major video and social platforms." },
      ],

      platformBadges: [
        { name: "youtube", icon: "mdi-youtube", color: "red darken-2" },
        { name: "vimeo", icon: "mdi-vimeo", color: "blue lighten-1" },
        { name: "facebook", icon: "mdi-facebook", color: "blue darken-2" },
        { name: "instagram", icon: "mdi-instagram", color: "purple lighten-1" },
        { name: "twitter", icon: "mdi-twitter", color: "blue" },
      ]
    })
  }
</script>
