<template>
  <v-footer id="footer" class="d-block mt-auto" padless dark>
    <v-sheet color="grey darken-3">
      <v-btn text v-on:click="$vuetify.goTo(0)" width="100%">
        Back to Top
      </v-btn>
    </v-sheet>
    <v-sheet style="height: 1px" color="grey darken-4"></v-sheet>
    <v-sheet class="blue--text text--darken-3" color="black">
      <v-container class="text-center">
        <v-row justify="center">
          <v-col cols="12" md="3">
            <h6 class="text-overline mb-2">
              {{ firstColumn.label }}
            </h6>
            <v-divider></v-divider>
            <v-list color="black" flat tile dense>
              <v-list-item v-for="(link, i) in firstColumn.links" :key="'fc-link-' + i" :href="link.href">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ link.label }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <!--<v-col cols="12" md="3">
            <h6 class="text-overline mb-2">
              {{ secondColumn.label }}
            </h6>
            <v-divider></v-divider>
            <v-list color="grey darken-4" flat tile dense>
              <v-list-item v-for="(link, i) in secondColumn.links" :key="'sc-link-' + i" :href="link.href">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ link.label }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>-->
          <v-col cols="12" md="3">
            <h6 class="text-overline mb-2">
              {{ thirdColumn.label }}
            </h6>
            <v-divider></v-divider>
            <v-list color="black" flat tile dense>
              <v-list-item v-for="(link, i) in thirdColumn.links" :key="'tc-link-' + i" :href="link.href">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ link.label }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto">
            <v-btn class="mx-3" :color="social.color" v-for="(social, i) in socialButtons" :key="'social-btn-' + i" :href="social.href" fab small>
              <v-icon dark>
                {{ social.icon }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center" cols="12">
            <div class="text-caption white--text">©{{ new Date().getFullYear() }} - Incandescent Digital</div>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({
    // Social links.
    socialButtons: [
      { label: "Facebook",  color: "blue darken-2",       icon: "mdi-facebook",   href: "#" },
      { label: "Instagram", color: "purple darken-1",     icon: "mdi-instagram",  href: "#" },
      { label: "Twitter",   color: "light-blue darken-1", icon: "mdi-twitter",    href: "#" },
    ],

    // First column links.
    firstColumn: {
      label: "Info",
      links: [
        { label: "About",           href: "/about" },
        { label: "Contact Us",      href: "https://incandescentdigital.com/" }
      ]
    },

    // Second column links.
    secondColumn: {
      label: "What You Can Do",
      links: [
        { label: "Shop Reminders",  href: "https://shop.myonerule.com/" },
        { label: "Sign The Pledge", href: "/#pledge-form" },
        { label: "Learn More",      href: "/learn" }
      ]
    },

    // Second column links.
    thirdColumn: {
      label: "Legal",
      links: [
        { label: "Privacy Policy",   href: "#" },
        { label: "Terms of Service", href: "#" }
      ]
    }
  })
}
</script>